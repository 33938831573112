
import { PosServiceDayPLan } from "@/model/api/PosServiceDayPlan";
import { Vue, Options, Prop } from "vue-property-decorator";
import moment from "moment";

@Options({
  name: "ServiceInfo",
  components: {},
})
export default class ServiceInfo extends Vue {
  @Prop() readonly servicePlan!: PosServiceDayPLan;
  @Prop() readonly active!: boolean;


  get isActive() {
    return this.active;
  }

  get serviceName() {
    return this.servicePlan?.service.name;
  }

  get slotAvailable() {
    return this.servicePlan?.total_slots_count;
  }

  get slotPlanned() {
    return this.servicePlan?.purchased_slots_count
  }

  get slotFree() {
    return this.servicePlan?.available_slots_count;
  }

  get amountNoIva() {
    return `${this.servicePlan?.total_amount_paid} €`
  }

  get isInThePast() {
    return moment(this.servicePlan?.service_date_at).isBefore(moment(), 'day')
  }

  onDelete() {
    this.$emit(
      'delete',
      this.servicePlan
    );
  }

  onClick() {
    this.$emit(
      'clickService',
      this.servicePlan
    );
  }
}
