
import { PosServiceDayPLan, PosTimeSlot } from "@/model/api/PosServiceDayPlan";
import { createSequenceFromTo } from "@/utils/utils";
import moment from "moment";
import { Prop, Options, Vue } from "vue-property-decorator";
import ServiceSlot from "./ServiceSlot.vue";

@Options({
  name: "PosCalendarService",
  inheritAttrs: false,
  components: {
    ServiceSlot
  }
})
export default class PosCalendarService extends Vue {
  @Prop({
    required: true,
  })
  public serviceDayPlan: PosServiceDayPLan;

  @Prop({
    default: 30
  })
  public duration: number;


  @Prop({
    required: true,
    default: ""
  })
  public forcustomer: string;


  isInTheRange(time: string) {
    const from = this.serviceDayPlan.service_time_from_at || process.env.VUE_APP_TIME_SLOTS_START;
    const to = this.serviceDayPlan.service_time_to_at || process.env.VUE_APP_TIME_SLOTS_END;

    return moment(time, 'HH:mm:ss').isBetween(
      moment(from, 'HH:mm:ss'),
      moment(to, 'HH:mm:ss'),
      'minutes', '[)'
    );
  }

  isTimeSlot(time: string, timeSlots: PosTimeSlot[]) {
    const timeSlot = timeSlots.find(t => t.from_time_at === `${time}:00`);
    return timeSlot ? 'mday__row__slots' : 'mday__row__slots--empty';
  }

  isTimeSlotNotEmpty(time: string, timeSlots: PosTimeSlot[], forcustomer: string) {
    const timeSlot = timeSlots.find(t => t.from_time_at === `${time}:00`);
    if (timeSlot)
    {
      if (forcustomer=="1" && timeSlot.order_id>0) return null;
      return timeSlot;
    }
    else
      return null;
  }

  getTimeSlotByTime(time: string, timeSlots: PosTimeSlot[]) {
    return timeSlots.find(t => t.from_time_at === `${time}:00`);
  }

  addAvailable(t: string) {
    this.$emit("add-available", t, this.serviceDayPlan, this.timeRange);
  }

  prev() {
    this.$emit("prev-service");
  }

  next() {
    this.$emit("next-service");
  }

  del() {
    this.$emit("delete-service");
  }

  edit() {
    this.$emit("edit-service");
  }

  /**
   * List of time slots Eg. from 08:00 to 20:00 each half hour
   */
  timeRange = [];

  get serviceMinTime() {
    return this.serviceDayPlan.service_time_from_at;
  }

  get serviceMaxTime() {
    return this.serviceDayPlan.service_time_to_at;
  }

  private initTimeRange() {
    const [from_hrs, from_mins] = (this.serviceDayPlan.service_time_from_at || process.env.VUE_APP_TIME_SLOTS_START.substring(0,5)).split(':');
    const [to_hrs, to_mins] = (this.serviceDayPlan.service_time_to_at || process.env.VUE_APP_TIME_SLOTS_END.substring(0,5)).split(':');
    const range = createSequenceFromTo(parseInt(from_hrs) * 60 + parseInt(from_mins), parseInt(to_hrs) * 60 + parseInt(to_mins), this.duration)
      .map(x => {
        return [Math.floor(x / 60), (x % 60)]
          .map(t => t.toString().padStart(2, '0'))
          .join(':');
      });

    this.timeRange = range;
  }

  mounted() {
    this.initTimeRange();
  }
}
