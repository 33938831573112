
import { PosCustomer } from "@/model/api/PosCustomer";
import { PosServiceDayPLan, PosTimeSlot } from "@/model/api/PosServiceDayPlan";
import { customersService } from "@services/customers.service";
import { posOrderService } from "@services/posOrder.service";
import { Vue, Options, Prop, Watch } from "vue-property-decorator";
import { City, Province } from '@/model/api/Domain';
import { domainService } from '@services/domain.service';
import { POSRoutesEnum } from "@/modules/pos/router";
import {
  Form as VForm,
  Field as VField
} from 'vee-validate';

@Options({
  name: "PosBuyForCustomerDialog",
  components: {
    VForm,
    VField
  }
})
export default class PosBuyForCustomerDialog extends Vue {
  @Prop() 
  readonly servicePlan!: PosServiceDayPLan;

  slotInfo: PosTimeSlot = new PosTimeSlot;
  filteredProvinces: Province[] = [];
  filteredCities: City[] = [];


  get day() {
    return this.slotInfo?.date_at
      ?.split('-')
      ?.slice(1)
      ?.reverse()
      ?.join('/');
  }

  get fromTo() {
    const { from_time_at, to_time_at } = this.slotInfo;
    const from  = from_time_at?.slice(0, from_time_at.length - 3);
    const to    = to_time_at?.slice(0, to_time_at.length - 3);

    return `${from} - ${to}`
  }

  searchCustomerData = {
    name        : "",
    surname     : "",
    email       : "",
    fiscal_code : "",
  };

  /**
   * Note for the order as anonymous
   */
  note: string = null; 

  /**
   * Search results
   */
  customers = [];

  /**
   * Selected customer after search
   */
  selectedCustomer: PosCustomer = null;

  get customerString() {
    const {name, surname} = (this.selectedCustomer || {});
    return `${name} ${surname}`;
  }

  newCustomer = {
    name        : null,
    surname     : null,
    email       : null,
    fiscal_code : null,
    ok_privacy : null,
    ok_terms : null,
    province_id : null,
    province : null,
    city_id : null,
    city : null,
  };

  /**
   * Current step
   * 0 - search customer
   * 1 - customer selection
   * 2 - new customer
   * 3 - selected customer details 
   * 4 - anonymous order
   * 5 - slot time
   */
  stepIndex: number = 5;
  lastStepIndex: number = 0;
  
  @Watch("stepIndex")
  onStepIndexChange(newValue: number, oldValue: number) {
    this.lastStepIndex = oldValue;
    this.isBuyError = false;
    this.isSearchFormInvalid = false;

    if (newValue === 2) {
      this.$nextTick(() => this.focusOnName());
    }
  }

  private focusOnName() {
    (this.$refs.newCustomerName as any)?.$el?.focus();
  }

  goBack() {
    if (this.stepIndex == 0){
      this.stepIndex = 5;
    } else {
      this.stepIndex--;
    }
  }

  selectTimeSlot(time_slot) {
    this.slotInfo = time_slot;
    this.stepIndex = 4;
    //this.stepIndex = 0;
    //Per tornare indietro stepIndex = 0 alla scelta tra cliente anonimo e la ricerca di un cliente registrato;
  }

  reset() {
    this.stepIndex = 5;
    
    Object.keys(this.searchCustomerData).forEach(key => {
      this.searchCustomerData[key] = "";
    });

    Object.keys(this.searchCustomerData).forEach(key => {
      this.newCustomer[key] = null;
    });

    this.selectedCustomer = null;
    this.note = "";
    this.isSearchFormInvalid = false;
    this.isBuyError = false;
  }

  isSearchFormInvalid: boolean = false;
  isSearchFormVisible: boolean = false;
  isBuyError: boolean = false;

  onSubmit() {
    this.isSearchFormInvalid = false;

    // Search customer data is composed by only strings
    // To be valid, at least 1 field must be filled
    const oneFieldRequired = 
      Object.keys(this.searchCustomerData)
        .filter((key) => this.searchCustomerData[key]?.length > 0)
        .length >= 1;

    if (oneFieldRequired) {
      this.searchCustomer();
    } else {
      this.isSearchFormInvalid = true;
    }
  }

  /**
   * Do a search for customers
   * and go to the next step
   */
  searchCustomer() {
    this.$waitFor(async () => {
      this.customers = 
        await customersService.searchCustomer(this.searchCustomerData);

      this.stepIndex = 1;
    });
  }

  /**
   * Save the order as anonymous
   * and close the dialog
   */
  saveAnonymous() {
    this.$waitFor(async () => {
      await posOrderService.buyCustomerSlot({
        customer_note               : this.note,
        pos_portfolio_time_slot_id  : this.slotInfo.id,
        date_at                     : this.slotInfo.date_at,
        from_time_at                : this.slotInfo.from_time_at,
        to_time_at                  : this.slotInfo.to_time_at,
        pos_portfolio_id            : this.slotInfo.pos_portfolio_id,
      });

      this.closeDialog(true);
      this.$successMessage(this.$t('order_completed_success'));
      //this.$router.back();

      this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_ORDERS });
    });
  }

  saveNewCustomer() {
    this.$waitFor(async () => {
      this.selectedCustomer = await customersService.createByPos(this.newCustomer);
      this.buyForCustomer();
    });
  }

  buyForCustomer() {
    this.isBuyError = true;
    this.$waitFor(async () => {
      await posOrderService.buyCustomerSlot({
        customer_id      : this.selectedCustomer.id,
        pos_portfolio_time_slot_id  : this.slotInfo.id,
        date_at          : this.slotInfo.date_at,
        from_time_at     : this.slotInfo.from_time_at,
        to_time_at       : this.slotInfo.to_time_at,
        pos_portfolio_id : this.slotInfo.pos_portfolio_id,
      });
      this.isBuyError = false;
      this.closeDialog(true);
      this.$successMessage(this.$t('order_completed_success'));
      this.$router.back();

    }, this.$t('pos.buy_for_customer.error.order_existing'));
  }

  closeDialog(refresh: boolean = false) {
    this.$emit("update:visible", false);

    if (refresh) {
      this.$emit("refresh");
    }
  }

  searchFormOnOff()
  {
    if (this.isSearchFormVisible)
      this.isSearchFormVisible = false;
    else
      this.isSearchFormVisible = true;
  }

  /*********
   * This function is called on show and hide of the popup
   */
  updated()
  {
    this.isSearchFormVisible = false;
    this.selectedCustomer = null;
    this.slotInfo = this.servicePlan?.time_slots[0];
  }

  async searchProvince({ query }) {
      const resp = await domainService.getProvincesAutocomplete(query, null);
      this.filteredProvinces = resp.data;
  }

  onSelectProvince({ value }) {
          if (value.id == this.newCustomer.province_id) return;

          this.newCustomer.province_id = value.id;

          this.newCustomer.city = null;
          this.newCustomer.city_id = null;
  }

  async searchCity({ query }) {
      const resp = await domainService.getCitiesAutocomplete(query, this.newCustomer.province_id);
      this.filteredCities = resp.data;
  }

  onSelectCity({ value }) {
          if (value.id == this.newCustomer.city_id) return;

          this.newCustomer.city_id = value.id;
  }
}
