import moment from "moment";
import { Options, Vue } from "vue-class-component";

import { 
  MyAutocomplete  
} from "@components";
import { Prop } from "vue-property-decorator";
import { CalendarRoutesEnum } from "../../router";
import { calendarStore } from "../../store";
import { authStore } from "@/modules/auth/store";
import { Service } from "@/model/api/Service";
import { posCalendarService } from "@services/posCalendar.service";
import OverlayPanel from "primevue/overlaypanel";
import { PosServiceDayPLan, PosTimeSlot } from "@/model/api/PosServiceDayPlan";
import PosBuyForCustomerDialog from "../pos-calendar-date/PosBuyForCustomerDialog.vue";


@Options({
  components:  {
    MyAutocomplete,
    PosBuyForCustomerDialog
  }
})
export default class PosCalendarPage extends Vue {
  
  @Prop({ default: "0" }) readonly forcustomer!: string;  
  
  /**
   * Resulst of fetching events
   */
  get events() {
    return calendarStore.getters.vueCalPosMonthEvents || [];
  }

  isTimeSlotVisible=false;

  startDate   = new Date();
  currentDate = new Date();
  
  /**
   * Selected service from dropdown
   */
  serviceId?: number = null; 

  /**
   * Available service options for the dropdown
   */
  services: Service[] = null;

  /**
   * A date is valid only if is in the future
   * @param date 
   * @returns 
   */
  isValidDate(date: any) {
    if (moment(date).isBefore(moment(), 'day')) {
      return false;
    }
    return true;
  }

  getClassCell(cell: any, events: any[]) {
    if (!events?.length && !this.isValidDate(cell.formattedDate)) {
      return 'blocked';
    } 

    else if (events.length > 0) {
      const isSoldOut = events.every(e => {
        const { count, total } = e.data;
        return count === total;
      });
      if (isSoldOut) return "sold_out";
      
      const isNoSale = events.every(
        e => e.data.count === 0
      );
      if (isNoSale) return "no_services";

      const isSale = events.some(e => {
        const { count, total } = e.data;
        return (count > 0) && (count < total);
      });
      if (isSale) return "sold_out";
    } 

    else {
      return 'no_services';
    }
  }

  applyFilters() {
    this.loadEvents();
    this.isTimeSlotVisible= true;
  }

  onCellClick(date: Date, events: any[]) {
    if (!events?.length) {
      return;
    }

    this.$router.push({
      name: CalendarRoutesEnum.POS_CALENDAR_DATE,
      params: {
        day: (date as any).format('YYYY-MM-DD'),
        forcustomer : this.forcustomer
      }
    })
  }

  onViewChange(event: any) {
    this.currentDate = new Date(event.startDate);
    this.loadEvents();
  }
 
  get currentPosId() {
    return authStore.getters.me?.entity_id;
  }

  private async loadEvents() {
    return this.$waitFor( async () => {
      return await calendarStore.actions.fetchPosEvents({
        currentPosId : this.currentPosId,
        month        : (this.currentDate as any).format('YYYY-MM'),
        serviceId    : this.serviceId,
        forcustomer  : this.forcustomer,
        available    : true
      });
    });
  }

  servicesIsLoading: boolean = false;
  private async loadServices() {
    try {
      this.servicesIsLoading = true;

      this.services = 
        await posCalendarService.fetchServices(this.currentPosId, true);
    } catch (error) {
      alert("Error");
    } finally {
      this.servicesIsLoading = false;
    }
  }

  get isNextEnabled() {
    return this.serviceId;
  }

  created() {
    this.loadServices();
    this.loadEvents();
  }

  /**
   * If true, display a "wizard" to fill a service slot for a customer.
   */
  showBuyForCustomer: boolean = false;

  /**
   * Selected service to delete
   */
  selectedService: PosServiceDayPLan = null;
   

   get servicesDayPlan() {
    const events = calendarStore.getters.posDayEvents;
    if (events) this.selectedService = events[0];
    return events;
  }

  slotInfo: PosTimeSlot = new PosTimeSlot();

  addOnAvailable(date: Date) {
    this.selectedService = this.events.filter(ev => ev.start == (date as any).format('YYYY-MM-DD'))[0].data.value;
    this.showBuyForCustomer = true;
  }

  closeDialog(refresh: boolean = false) {
    this.$emit("update:visible", false);

    if (refresh) {
      this.$emit("refresh");
    }
  }

}