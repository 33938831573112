
  import { PosTimeSlot } from "@/model/api/PosServiceDayPlan";
  import { Vue, Options, Prop } from "vue-property-decorator";
  import moment from "moment";

  @Options({
    name: "ServiceSlot",
  })
  export default class ServiceSlot extends Vue {

    @Prop() serviceName: string;

    @Prop() posTimeSlot: PosTimeSlot;

    @Prop({ default: false }) isBlocked: boolean;

    @Prop({ default: true }) forcustomer: string;

    get isPlanned() {
      return !!this.posTimeSlot?.order_id;
    }

    get isAvailable() {
      return this.posTimeSlot && !this.posTimeSlot?.order_id;
    }

    get isInThePast() {
      return moment(this.posTimeSlot?.date_at + " " + this.posTimeSlot?.from_time_at).toDate() < new Date()
    }

    get isAffordable() {
      if(this.forcustomer!="1")
        return null;
      else
        return this.posTimeSlot;
    }

    get isEmpty() {
      return  !this.posTimeSlot;
    }

    get customerName() {
      return this.posTimeSlot?.customer_name;
    }

    get anonymousText() {
      return this.posTimeSlot?.customer_note;
      // return `Testo di un ordine anonimo che potrebbe essere abbastanza lungo\npotrebbe andare a capo ogni tanto\necc\necc`;
    }

    emitAddOnAvailable() {
      this.$emit("add-on-available", this.posTimeSlot);
    }

    removeAvailable() {
      this.$emit("remove-available", this.posTimeSlot);
    }

    addAvailable() {
      this.$emit("add-available");
    }

    removeSlot() {
      this.$emit('remove-slot', this.posTimeSlot);
    }
  }
