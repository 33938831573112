import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { PosCustomer, SerachCustomerParams } from '@/model/api/PosCustomer';
import { ProviderCustomer } from '@/model/api/ProviderCustomer';
import axios from 'axios';
import { CRUD } from './base/crud';

class CustomersService extends CRUD<ProviderCustomer, any> {
    readonly endPoint = `customers`;

    public async providerCustomers(providerID: number, params?: any) {
        if (params?.filters) {
            params.filters = this.getFilterParam(params.filters);
        }

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const res = await this.get<PaginatedResponse<any>>(
            `providers/${providerID}/customers`,
            { params, cancelToken }
        );
        
        return res;
    }

    public async searchCustomer(params: SerachCustomerParams) {
        return this.get<PosCustomer[]>(`${this.endPoint}/search`, { params });
    }

    public async createByPos(body: SerachCustomerParams) {
        return this.post<PosCustomer>(
            `${this.endPoint}/create-by-pos`, 
            body
        );
    }
}

export const customersService = new CustomersService();



