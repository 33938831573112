import { Order } from '@/model/api/Order';
import { CRUD } from './base/crud';

interface BuySlotParams {
    customer_id?: number; 

    pos_portfolio_id: number;

    customer_note?: string;
    pos_portfolio_time_slot_id: number;
    /**
     * Date format: yyyy-MM-dd
     */
    date_at: string;

    /**
     * Time format: HH:mm:00
     */
    from_time_at: string;
    
    /**
     * Time format: HH:mm:00
     */
    to_time_at: string;

}

class PosOrderService extends CRUD<Order, any> {
    readonly endPoint = `orders`;

    public async buyCustomerSlot(body: BuySlotParams) {
        return this.post<any>(
            `${this.endPoint}/pos-buys-customer-slot`, 
            body
        );
    }

    public async cancelCustomer(id: number) {
        return this.post<any>(
            `${this.endPoint}/${id}/cancel-customer`
        );
    }
}

export const posOrderService = new PosOrderService();



