import { BaseApiModel } from "../common/BaseApiModel"

export class PosServiceDayPLan extends BaseApiModel {
  service_date_at: string;
  service_from_date_at: string;
  service_to_date_at: string;

  service_id: number;
  service_time_from_at: string;
  service_time_to_at: string;
  total_slots_count: number;
  available_slots_count: number;
  purchased_slots_count: number;
  total_amount_paid: number;
  service: ServiceInfo;
  time_slots: PosTimeSlot[];
  gross_price: number; 
  duration: number;
}

interface ServiceInfo {
  id: number;
  name: string;
  image_path_url: string;
}

export class PosTimeSlot {
  id: number;
  pos_portfolio_id: number;
  date_at: string;
  from_time_at: string;
  to_time_at: string;
  order_id: number;
  customer_name: string;
  customer_note: string;
  text:string;
}