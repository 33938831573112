import { PosServiceDayPLan, PosTimeSlot } from "@/model/api/PosServiceDayPlan";
import { authStore } from "@/modules/auth/store";
import {
  MyAutocomplete
} from "@components";
import { posCalendarService } from "@services/posCalendar.service";
import { posOrderService } from "@services/posOrder.service";
import { posPortfolioTimeSlotsService } from "@services/posPortfolioTimeSlots.service";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CalendarRoutesEnum } from "../../router";
import { POSRoutesEnum } from '@/modules/pos/router';
import { calendarStore } from "../../store";
import PosBuyForCustomerDialog from "./PosBuyForCustomerDialog.vue";
import PosCalendarService from "./PosCalendarService.vue";
import PosServiceInfo from "./PosServiceInfo.vue";
@Options({
  components: {
    MyAutocomplete,
    PosServiceInfo,
    PosBuyForCustomerDialog,
    PosCalendarService
  }
})

export default class PosCalendarDatePage extends Vue {
  /**
   * Selected date: YYYY-MM-DD
   */
  @Prop() readonly day!: string;
  
  @Prop() readonly forcustomer!: string;

  get title() {
    return moment(this.day).format('dddd DD MMMM');
  }

  /**
   * If true, display an alert dialog before delete some service slot.
   */
  showDeleteDialog: boolean = false;

  /**
   * If true, display a "wizard" to fill a service slot for a customer.
   */
  showBuyForCustomer: boolean = false;

  /**
   * Selected service to delete
   */
  selectedService: PosServiceDayPLan = null;

  currentDate = new Date();

  get servicesDayPlan() {
    const events = calendarStore.getters.posDayEvents;
    if (events) this.selectedService = events[0];
    return events;
  }

  get currentPosId() {
    return authStore.getters.me?.entity_id;
  }

  previousDay() {
    this.$router.push({
      name: CalendarRoutesEnum.POS_CALENDAR_DATE,
      params: {
        day: moment(this.day)
          .subtract(1, 'day')
          .format('yyyy-MM-DD'),
        forcustomer : this.forcustomer
      },
      force: true
    })
    
  }

  nextDay() {
    this.$router.push({
      name: CalendarRoutesEnum.POS_CALENDAR_DATE,
      params: {
        day: moment(this.day)
          .add(1, 'day')
          .format('yyyy-MM-DD'),
        forcustomer : this.forcustomer
      },
      force: true
    })
  }

  nextService() {
    const events = calendarStore.getters.posDayEvents;
    for (let i = 0; i < events.length; i++) {
      if (events[i] == this.selectedService) {
        let next = i+1;
        if (next >= events.length) next = 0;
        this.selectedService = events[next];
        break;
      }
    }
  }

  prevService() {
    const events = calendarStore.getters.posDayEvents;
    for (let i = events.length - 1; i >= 0 ; i--) {
      if (events[i] == this.selectedService) {
        let prev = i-1;
        if (prev < 0) prev = events.length - 1;
        this.selectedService = events[prev];
        break;
      }
    }
  }

  getTimeSlotByTime(time: string, timeSlots: PosTimeSlot[]) {
    return timeSlots.find(t => t.from_time_at === `${time}:00`);
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    this.loadEvents(to.params.day);
    next();
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    calendarStore.mutations.setPosDayEvents([]);
    next();
  }

  onDeleteService(service: PosServiceDayPLan) {
    //this.selectedService = service;
    this.showDeleteDialog = true;
  }

  onSelectService(service: PosServiceDayPLan) {
    this.selectedService = service;
  }

  slotInfo: PosTimeSlot = null; 
  addOnAvailable(slotInfo: PosTimeSlot, sp: PosServiceDayPLan) {
    this.slotInfo = slotInfo;
    this.selectedService = sp;
    this.showBuyForCustomer = true;
  }

  async removeAvailable(slot: PosTimeSlot) {
    const response = await this.$confirmMessage("Eliminare lo slot?")
    

    if (response) {
      this.$waitFor(async () => {
        await posPortfolioTimeSlotsService.deleteTimeSLot(slot.id);
        this.refresh();
      }, 'Cancellazione slot non riuscita');
    }
  }

  addAvailable(time: string, sp: PosServiceDayPLan, timeRange: string[]) {
    const i = timeRange.indexOf(time);

    this.$waitFor(async () => {
      await posPortfolioTimeSlotsService.addTimeSLot({
        date_at          : moment(this.day).format('yyyy-MM-DD'),
        from_time_at     : time,
        to_time_at       : timeRange[i + 1],
        pos_portfolio_id : sp.id
      });

      this.refresh();
    }, 'Aggiunta slot non riuscita');
  }

  async removeSlot(slot: PosTimeSlot) {
    const response =  await this.$confirmMessage(
      "Eliminare lo slot e annullare l'appuntamento?"
    );

    if (response) {
      this.$waitFor(async () => {
        await posOrderService.cancelCustomer(slot.order_id);
        
        this.refresh();
      }, 'Cancellazione appuntamento non riuscita');
    }

  }

  get inThePast() {
      return moment(this.day).toDate() < new Date()
  }

  deleteServiceDate() {
    this.$waitFor(async () => {
      await posCalendarService.cancelServiceDate({
        date_at: moment(this.day).format('yyyy-MM-DD'),
        pos_portfolio_id: this.selectedService.id
      })

      this.showDeleteDialog = false;

      this.refresh();
    }, 'Cancellazione servizio non riuscita');
  }

  refresh() {
    this.loadEvents(this.day);
  }

  private loadEvents(date: string) {
    return calendarStore.actions.fetchPosDayEvents({
      currentPosId: this.currentPosId,
      date,
    });
  }

  async created() {
    if (!this.day) {
      this.$router.replace({
        name: CalendarRoutesEnum.POS_CALENDAR
      });

      return;
    }

    await this.loadEvents(this.day);
  }

  goToDetail() {
    this.$router.push({ name: POSRoutesEnum.PORTFOLIO_EDIT, params: { portfolioId: this.selectedService.id } });
  }
}