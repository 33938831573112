import { PosTimeSlot } from '@/model/api/PosServiceDayPlan';
import { CRUD } from './base/crud';

interface TimeSlotData {
    pos_portfolio_id: number;
    date_at: string;
    from_time_at: string;
    to_time_at: string;
}
class PosPortfolioTimeSlotsService extends CRUD<PosTimeSlot, any> {
    readonly endPoint = `pos-portfolio`;

    addTimeSLot(data: TimeSlotData) {
        return this.post(`pos-portfolio-time-slots`, data);
    }
    deleteTimeSLot(id: number) {
        return this.delete(`pos-portfolio-time-slots/${id}`);
    }
}

export const posPortfolioTimeSlotsService = new PosPortfolioTimeSlotsService();